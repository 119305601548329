import React, {useState} from "react";
import homeData from "@/i18n/json/home";
import ProjectPartnersCarouselItem from "./project-partners-carousel-item";
import Arrow from "@/components/arrow";
// Tailwind Classes
import {wrapper, nextButton, prevButton, dotsClasses} from "./style/carousel-classes";

import Slider from "react-slick";
import {LazyLoadComponent} from "react-lazy-load-image-component";

const ProjectPartnersCarousel: React.FC = () => {
    const projects = homeData.data["projects-and-partners"].projects;

    const CustomArrow: React.FC<{ dir: "left" | "right", onClick?: () => void }> = ({dir, onClick}) => {
        return <button type={"button"} className={`${dir === "right" ? prevButton.join(" ") : nextButton.join(" ")}`} onClick={onClick}>
            <Arrow dir={dir} color={"border-white"}/>
        </button>
    }

    const settings = {
        centerMode: true,
        infinite: true,
        dots: true,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 500,
        nextArrow: <CustomArrow dir={"left"}/>,
        prevArrow: <CustomArrow dir={"right"}/>,
        appendDots: (dots: any) => {
            return (
                <ul>
                    {dots.map((item: any) => {
                        const dotClass = [...dotsClasses]
                        if (item.props.className === "slick-active") {
                            if (dotClass.indexOf("bg-gray-200") !== -1) {
                                dotClass.splice(dotClass.indexOf("bg-gray-200"));
                                dotClass.push(...["bg-primary"]);
                            }
                        }
                        return (
                            <li key={item.key} className={dotClass.join(" ")}/>
                        );
                    })}
                </ul>
            );
        }
    }

    return <LazyLoadComponent>
        <Slider {...settings}>
            {projects.map((item, index) => {
                return <div className={wrapper.join(" ")} key={index}>
                    <ProjectPartnersCarouselItem key={index} {...item} />
                </div>;
            })}
        </Slider>
    </LazyLoadComponent>
};

export default ProjectPartnersCarousel;
