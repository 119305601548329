export const wrapper = [
    // Mobile
    "mobile:flex-col",
    "mobile:justify-top",
    "mobile:-mt-20",
    // Tablet
    "tablet:flex-row",
    "tablet:justify-between",
    "tablet:-mt-0",
    // All
    "flex",
    "items-center",
];
export const img = [
    // Mobile
    "mobile:w-40",
    // Tablet
    "tablet:w-52",
];
export const headingContainer = [
    // Mobile
    "mobile:w-full",
    "mobile:items-center",
    "mobile:justify-center",
    "mobile:mt-4",
    // Tablet
    "tablet:w-56",
    "tablet:ml-14",
    "tablet:items-start",
    "tablet:justify-start",
    "tablet:mt-0",
    // All
    "flex",
    "flex-col",
];
export const descriptionContainer = [
    // Mobile
    "mobile:w-full",
    // Tablet
    "tablet:pl-10",
    "tablet:ml-7",
    "tablet:w-8/12",
    // All
    "font-light",
];
// Global classes
const headings = [
    // Mobile
    "mobile:text-xl",
    // Tablet
    "tablet:text-3xl",
    // All
    "text-white",
    "font-bold",
];

const subheadings = [
    // Mobile
    "mobile:text-base",
    "mobile:w-56",
    "mobile:text-center",
    // Tablet
    "tablet:text-1xl",
    "tablet:w-auto",
    "tablet:text-left",
    // All
    "text-white",
    "font-medium",
];

export const heading = [
    ...headings,
    // All
    "pb-6",
];
export const subheading = [
    // Mobile
    "mobile:pb-4",
    // Tablet
    "tablet:pb-8",
    // All
    ...subheadings,
];
export const button = [
    // Mobile
    "mobile:w-52",
    "mobile:h-11",
    "mobile:text-lg",
    "mobile:font-semibold",
    "mobile:mt-2",
    "mobile:mb-5",
    // Tablet
    "tablet:w-64",
    "tablet:h-12",
    "tablet:text-xl",
    "tablet:mt-0",
    "tablet:font-normal",
    // All
    "bg-white",
    "rounded-lg",
];
export const description = [
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-xl",
    // Al
    "text-white",
    "font-extralight",
    "mb-6",
];

// Hora classes
export const horaContainer = [
    // Mobile
    "mobile:ml-0",
	"mobile:pl-0",
    "mobile:pr-0",
    // Tablet
    "tablet:ml-10",
    "tablet:pl-10",
    "tablet:pr-10",
    // All
    "flex",
    "flex-col",
    "items-start",
    "justify-start",
];
export const horaHeadingContainer = [
    // Mobile
    "mobile:flex-col",
    "mobile:items-center",
    "mobile:justify-center",
    "mobile:mt-4",
    // Tablet
    "tablet:flex-row",
    "tablet:items-center",
    "tablet:justify-start",
    "tablet:mt-0",
    // All
    "flex",
    "items-center",
    "justify-start",
    "w-full",
    "mb-6",
];
export const disclaimerContainer = [
    // Mobile
    "mobile:flex-col",
    // Tablet
    "tablet:flex-row",
    // All
    "flex",
    "items-center",
    "justify-start",
];

export const horaHeading = [...headings];
export const horaSubHeading = [
    // Mobile
    "mobile:ml-0",
    // Tablet
    "tablet:ml-20",
    // All
    ...subheadings,
    "font-medium",
];
export const disclaimerButton = [
    // Mobile
    "mobile:order-2",
    // Tablet
    "mobile:order-1",
    ...button,
];
export const disclaimerText = [
    // Mobile
    "mobile:w-full",
    "mobile:order-1",
    "mobile:text-xs",
    // Tablet
    "tablet:pl-10",
    "tablet:w-5/6",
    "tablet:text-xs",
    "tablet:order-2",
    // All
    "ml-auto",
    "text-white",
    "italic",
    "font-light",
];
