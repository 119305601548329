import ContentHeading from "@/components/content-heading";
import { ILatestBlogs } from "./interfaces";
import { useTranslation } from "react-i18next";
// Tailwind classes
import { wrapper, blogs } from "./styles";

const LatestBlogs: React.FC<ILatestBlogs> = ({ component }) => {
    const { t } = useTranslation();

    return (
        <section className={wrapper} title={t("index.latest-blogs.seo.title")} aria-label={t("index.latest-blogs.seo.aria-label")}>
            <ContentHeading
                h="p"
                className="justify-center"
                backdropPosition="mobile:left-4 tablet:left-8"
                fontSize="mobile:text-2xl tablet:text-4xl"
                background="bg-primary bg-opacity-40"
                color="text-white"
            >
                {t("index.latest-blogs.heading")}
            </ContentHeading>
            <div className={blogs}>{component}</div>
        </section>
    );
};

export default LatestBlogs;
