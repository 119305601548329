import classNames from "classnames";
import scss from "@/styles/pages/home/customer-videos.module.scss";

export const wrapper = classNames(
    // Mobile
    "mobile:items-start",
    // Tablet
    "mobile-lg:items-center",
    // All
    scss["wrapper"],
    "bg-secondary",
    "flex",
    "justify-between"
);
export const content = classNames(
    // Mobile
    "mobile:mt-12",
    "mobile:mr-4",
    // Mobile larger
    "mobile-lg:mt-4",
    "mobile-lg:mr-40",
    // Tablet
    "tablet:mt-0",
    "tablet:mr-24",
    // Laptop
    "laptop:mr-96",
    "laptop:pr-32",

);
export const button = classNames(
    // Mobile
    "mobile:w-44",
    "mobile:h-8",
    "mobile:text-base",
    "mobile:font-semibold",
    "mobile:mt-5",
    // Tablet
    "tablet:w-64",
    "tablet:h-12",
    "tablet:text-xl",
    "tablet:mt-12",
    "tablet:font-normal",
    // All
    "bg-white",
    "rounded-lg"
);
