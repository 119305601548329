import classNames from "classnames";
export const background = classNames(
    // Mobile
    "mobile:bg-[url('/assets/images/backgrounds/home/header/mobile-medium.jpg')]",
    "mobile:bg-[url('/assets/images/backgrounds/home/header/mobile-medium.webp')]",
    "mobile:bg-[position:center_100%]",
    "mobile:[background-size:157%_auto]",
    "mobile:bg-no-repeat",
    // Mobile-lg
    "mobile-lg:bg-[url('/assets/images/backgrounds/home/header/mobile-large.jpg')]",
    "mobile-lg:bg-[url('/assets/images/backgrounds/home/header/mobile-large.webp')]",
    "mobile-lg:bg-[position:center_64%]",
    "mobile-lg:bg-cover",
    // Tablet
    "tablet:bg-[url('/assets/images/backgrounds/home/header/desktop-small.jpg')]",
    "tablet:bg-[url('/assets/images/backgrounds/home/header/desktop-small.webp')]",
    "tablet:bg-[position:center_30%]",
    "tablet:bg-cover",
    // Laptop
    "laptop:bg-[url('/assets/images/backgrounds/home/header/desktop-medium.jpg')]",
    "laptop:bg-[url('/assets/images/backgrounds/home/header/desktop-medium.webp')]",
    "laptop:hidden",
    // Desktop
    "desktop:bg-[url('/assets/images/backgrounds/home/header/desktop-large.jpg')]",
    "desktop:bg-[url('/assets/images/backgrounds/home/header/desktop-large.webp')]",
    // All
    "absolute",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "z-0",
    "overflow-hidden"
);

export const backdrop = [
    // All
    "absolute",
    "w-full",
    "h-full",
    "bg-[color:#404040]",
    "bg-opacity-[52%]",
    "z-10"
];

export const video = [
    "absolute",
    "left-1/2",
    "top-1/2",
    "transform",
    "-translate-y-1/2",
    "-translate-x-1/2",
    "w-full",
    "h-auto",
    "transition-all",
    "duration-700",
]
