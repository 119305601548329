export const deepMerge = (source: any, target: any) => {
  if (typeof target !== "object" || typeof source !== "object") {
    return source;
  }
  for (const key in source) {
    if (typeof source[key] === "object") {
      if (!target[key]) {
        Object.assign(target, { [key]: {} });
      }
      deepMerge(source[key], target[key]); // Swap source and target
    } else {
      Object.assign(target, { [key]: source[key] });
    }
  }
  return target;
};
