import ContentHeading from "@/components/content-heading";
import CustomLink from "@/components/custom-link";
import { useTranslation } from "react-i18next";
// Tailwind classes
import { wrapper, p, button } from "./styles/published-research";

const PublishedResearch: React.FC = () => {
    const { t } = useTranslation();
    return (
        <section className={wrapper} title={t("index.published-research.seo.title")} aria-label={t("index.published-research.seo.aria-label")}>
            <ContentHeading
                h="p"
				className="mobile:justify-center tablet:justify-start"
                backdropPosition="mobile:left-4 tablet:left-5"
                fontSize="mobile:text-2xl tablet:text-4xl"
                background="bg-primary bg-opacity-40"
            >
                {t("index.published-research.heading")}
            </ContentHeading>
            <h3 className={p}>{t("index.published-research.content")}</h3>
            <CustomLink url="/published-research">
                <button className={button} type="button">
                    {t("index.published-research.button")}
                </button>
            </CustomLink>
        </section>
    );
};

export default PublishedResearch;
