import classNames from "classnames";

export const wrapper = classNames("relative w-full max-w-[1250px] m-auto");

const button = [
    // All
    "absolute",
    "transform",
    "p-1",
    "z-40"
];
export const nextButton = classNames(
    // Mobile
    "mobile:-translate-x-3",
    // Tablet
    "tablet:-translate-x-12",
    // All
    ...button,
    "right-1",
    "top-1/2",
    "-translate-y-1/2",
);
export const prevButton = classNames(
    // Mobile
    "mobile:translate-x-3",
    // Tablet
    "tablet:translate-x-12",
    // All
    ...button,
    "left-1",
    "top-1/2",
    "-translate-y-1/2",
);
