import ContentHeading from "@/components/content-heading";
import { useTranslation } from "react-i18next";
import ProjectPartnersCarousel from "./project-partners-carousel";
// Tailwind classes
import { wrapper } from "./style/projects-partners-classes";

const ProjectsAndPartners: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={wrapper.join(" ")}>
            <ContentHeading h="p" className="justify-center" backdropPosition="mobile:left-4 tablet:left-8" fontSize="mobile:text-2xl tablet:text-4xl" background="bg-primary bg-opacity-40">
                {t("index.projects-and-partners.heading")}
            </ContentHeading>
            <ProjectPartnersCarousel />
        </div>
    );
};

export default ProjectsAndPartners;
