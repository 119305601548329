import { deepMerge } from '@/lib/deepMerge';
import CloseButton from "../close-button";
import { IVideoPopup } from "./interfaces/IVideoPopup";
import { wrapper, overlay, closeButton, videoContainer, player, loading } from "./styles";
// Youtube Plugin
import YouTube from "react-youtube";

const PopupVideo: React.FC<IVideoPopup> = ({ closeHandler, youtubeId, url, options = {} }) => {
    const playerOptions: Object = deepMerge(options, {
        width: "100%",
        height: "100%",
        playerVars: {
            // Autoplay + mute has to be activated (value = 1) if you want to autoplay it everywhere
            // Chrome/Safari/Mobile
            // autoplay: 1,
            mute: 0,
            rel: 0,
            showinfo: 0,
            controls: 0,
            disablekb: 1,
            enablejsapi: 1,
            iv_load_policy: 3,
            // For looping video you have to have loop to 1
            // And playlist value equal to your currently playing video
            loop: 1,
            playlist: youtubeId,
        },
    });
    console.log({playerOptions})
    return (
        <div className={wrapper}>
            <div
                className={overlay}
                onClick={() => {
                    closeHandler();
                }}
            ></div>
            <div className={videoContainer}>
                <div className={closeButton}>
                    <CloseButton
                        closeHandler={() => {
                            closeHandler();
                        }}
                        color="bg-white"
                    />
                </div>
                <p className={loading}>Preparing Video Please Wait...</p>
                <YouTube className={player} containerClassName={player} id={youtubeId} opts={playerOptions} />
            </div>
        </div>
    );
};

export default PopupVideo;
