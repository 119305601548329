// Tailwind css
export const wrapper = [
    // Mobile
    // Table
    "tablet:block",
    "tablet:relative",
    "tablet:w-full",
    "tablet:h-full",
    "tablet:pb-0",
    // All
    "bg-secondary",
];

export const firstLevelUl = [
    // Mobile
    "mobile:flex-row",
    "mobile:flex-wrap",
    "mobile:pt-0",
    // Laptop & higher
    "tablet:flex-row",
    "tablet:items-center",
    "tablet:justify-center",
    "tablet:pt-0",
    // All
    "flex",
    "w-full",
    "h-full",
    "m-auto",
    "bg-secondary",
];

export const secondLevelUl = [
    // Mobile
    "mobile:shadow-none",
    "mobile:absolute",
    "mobile:top-full",
    "mobile:left-4",
    "mobile:items-center",
    "mobile:justify-start",
    "mobile:w-auto",
    "mobile:shadow-navigation",
    "mobile:pb-4",
    "mobile:pt-4",
    // Tablet
    "tablet:left-0",
    // All
    "flex",
    "flex-col",
    "z-20",
    "bg-secondary",
];

export const firstLevelLi = [
    // Mobile
    "mobile:relative",
    "mobile:flex-col",
    "mobile:w-1/2",
    "mobile:h-auto",
    "mobile:items-center",
    "mobile:justify-center",
    // Laptop & higher
    "tablet:relative",
    "tablet:flex-row",
    "tablet:items-center",
    "tablet:justify-center",
    "tablet:w-1/5",
    "tablet:h-full",
    "tablet:text-lg",
    // All
    "flex",
    "w-auto",
    "h-full",
    "bg-secondary",
    "font-light",
    "text-white",
    "whitespace-nowrap",
    "cursor-pointer",
];

export const firstLevelLiText = [
    // Mobile
    "mobile:h-16",
    "mobile:text-left",
    "mobile:w-full",
    "mobile:justify-center",
    // Tablet & higher
    "tablet:h-full",
    "tablet:w-full",
    "tablet:border-none",
    "tablet:justify-center",
    // All
    "flex",
    "items-center",
    "block",
];

export const secondLevelLi = [
    // Mobile
    "mobile:flex",
    "mobile:items-center",
    "mobile:justify-center",
    "mobile:p-8",
    // Tablet
    "tablet:items-start",
    "tablet:justify-center",
    "tablet:w-96",
    "tablet:pl-12",
    "tablet:pr-4",
    "tablet:border-r-20",
    // All
    "flex",
    "flex-col",
    "h-14",
    "text-white",
    "bg-secondary",
    "whitespace-nowrap",
    "cursor-pointer",
];

export const secondLevelLiText = [
    // All
    "mobile:w-60",
    "mobile:text-base",
    // Tablet & higher
    "tablet:w-full",
    // All
    "block",
    "font-bold",
];

export const description = [
    // Mobile
    "mobile:w-60",
    "mobile:text-sm",
    // Tablet & higher
    "tablet:w-full",
    // All
    "font-thin",
];
