export const wrapper = [
    // Mobile
    "mobile:mb-2",
    // Tablet
    "tablet:mb-4",
    // All
    "flex",
    "items-end",
    "justify-center",
    "w-full",
    "max-w-full",
];
export const activeProduct = [
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
];
const products = [
    // Mobile
    "mobile:w-1/12",
    "mobile-lg:w-2/12",
    // Tablet
    "tablet:w-1/4",
    "tablet:max-w-1/4",
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
];
export const leftProducts = [...products];
export const rightProducts = [...products];

// Content
const content = ["mb-4", "text-center"];
export const productHeading = [
    // Mobile
    "mobile:text-xl",
    // Tablet & Higher
    "tablet:text-3xl",
    // All
    ...content,
    "font-bold",
];
export const subHeading = [
    // Mobile
    "mobile:text-sm",
    "mobile:h-10",
    // Tablet
    "tablet:text-xl",
    "tablet:h-auto",
    // All
    ...content,
];

// Read More Button
export const productButton = [
    // Mobile
    "mobile:text-lg",
    "mobile:w-64",
    "mobile:h-11",
    // Tablet
    "tablet:w-60",
    "tablet:h-12",
    "tablet:text-xl",
    // All
    ...content,

    "rounded-lg",
    "bg-secondary",
    "text-white",
    "text-center",
];

// Image containers
const imageContainer = [
    // Mobile
    "mobile:h-56",
    "mobile-lg:h-72",
    // Tablet
    "tablet:h-auto",
    "tablet:min-h-lg",
    // All
    "relative",
    "flex",
    "items-center",
    "justify-center",
];
export const activeImageContainer = [
    // Mobile
    "mobile:w-full",
	"mobile:pl-6",
    "mobile:pr-6",
    // Tablet
    "tablet:w-full",
    "tablet:pl-14",
    "tablet:pr-14",
    // All
    ...imageContainer,
    "relative",
];
export const leftImagesContainer = [...imageContainer, "w-full", "overflow-hidden"];
export const rightImagesContainer = [...imageContainer, "w-full", "overflow-hidden"];

// Images
export const activeImage = [
    // Mobile
    "mobile:max-w-full",
    "mobile:w-auto",
    "mobile:max-h-48",
    // // Tablet
    "tablet:max-w-full",
    "tablet:h-auto",
    "tablet:max-h-lg",
];
export const leftImages = [
    // Mobile
    "mobile:right-0",
    "mobile:h-36",
    // Tablet
    "tablet:right-40",
    "tablet:h-72",
    // All
    "absolute",
    "opacity-50",
    "w-auto",
    "max-w-none",
];
export const rightImages = [
    // Mobile
    "mobile:left-0",
    "mobile:h-36",
    // Tablet
    "tablet:left-40",
    "tablet:h-72",
    // All
    "absolute",
    "opacity-50",
    "w-auto",
    "max-w-none",
];
