export const wrapper = [
    // Mobile
    "mobile:mt-24",
    "mobile:pl-8",
    "mobile:pr-8",
    "mobile:pb-8",
    // Tablet
    "tablet:mt-10",
    "tablet:pl-14",
    "tablet:pr-14",
    "tablet:pb-5",
    // All
    "relative",
    "bg-secondary",
    "rounded-xl",
    "pt-5",
    "mt-10",
];
const button = ["absolute", "transform", "-translate-y-1/2", "z-40","flex",
    "items-center",
    "justify-center",
    "w-[40px]",
    "h-[40px]",];
export const nextButton = [
    // Mobile
    "mobile:right-2",
    "mobile:top-[21rem]",
    // Tablet
    "tablet:right-6",
    "tablet:top-1/2",
    // All
    ...button,
];
export const prevButton = [
    // Mobile
    "mobile:left-2",
    "mobile:top-[21rem]",
    // Tablet
    "tablet:left-6",
    "tablet:top-1/2",
    // All
    ...button,
];

export const dotContainer = [
    // Mobile
    "mobile:mt-4",
    "mobile:w-16",
    // Tablet
    "tablet:mt-4",
    "tablet:w-24",
    // All
    "flex",
    "ml-auto",
    "mr-auto",
    "items-center",
    "justify-evenly",
];
export const dotsClasses = [
    // Mobile
    "mobile:!w-2",
    "mobile:!h-2",
    // Tablet
    "tablet:!w-3",
    "tablet:!h-3",
    // All
    "rounded-full",
    "bg-gray-200",
];
