import React, { useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
// Functions / handlers
import { getUpdatedState, getAllParentNodes } from "@/lib/navigation";
// State management
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { setProductNavigationItemState, getProductNavigationState } from "@/redux/slices/product-navigation";
// Global component
import CustomLink from "@/components/custom-link";
import JsxParser from "react-jsx-parser";
// Tailwind classes
import {
    wrapper,
    firstLevelUl,
    secondLevelUl,
    firstLevelLi,
    secondLevelLi,
    firstLevelLiText,
    secondLevelLiText,
    description,
} from "./styles/navigation-items-classes";
// Interfaces
import {INavigationItem} from "@/components/navigation/interfaces/INavigationItem";

const NavigationItems: React.FC = () => {
    const ref = useRef<HTMLElement>(null);
    const dispatch = useAppDispatch();
    // Getting the navigation state from the store
    const navigationState = [...useAppSelector(getProductNavigationState)];

    /**
     * Function that handles hover effects to set the navigation state to show sub items
     * @param parentState => from what part of the state the hovering item comes from
     * @param index => the index of the item within the parent state
     */
    const handleOnMouseEnter = (parentState: INavigationItem[], index: number): void => {
        const navigationMockState = [...getUpdatedState([...navigationState], parentState, index, true)];
        dispatch(setProductNavigationItemState(navigationMockState));
    };

    /**
     * Function that handles hover effects to set the navigation state to hide sub items
     * @param parentState => from what part of the state the hovering item comes from
     * @param index => the index of the item within the parent state
     */
    const handleOnMouseLeave = (parentState: INavigationItem[], index: number): void => {
        const navigationMockState = [...getUpdatedState([...navigationState], parentState, index, false)];
        dispatch(setProductNavigationItemState(navigationMockState));
    };

    /**
     * Function that handles mobile click effects to set the navigation state to show sub items
     * @param parentState => from what part of the state the hovering item comes from
     * @param index => the index of the item within the parent state
     */
    const handleOnClick = (parentState: INavigationItem[], index: number): void => {
        const navigationMockState = [...getUpdatedState([...navigationState], parentState, index, true)];
        dispatch(setProductNavigationItemState(navigationMockState));
    };

    /**
     * A component that handles the navigation  state to display navigation items
     * @param navigationItems => The nav items that needs to be displayed
     * @param listLevel => The level of the navigation items
     * @param centerDropdown => Sets how the second level items will display
     * @param isLastSubMenu => Sets how the third level last item will display
     * @returns
     */
    const ListNavigationItems: React.FC<{
        navigationItems: INavigationItem[];
        listLevel: number;
        centerDropdown?: boolean;
        isLastSubMenu?: boolean;
    }> = ({ navigationItems, listLevel, centerDropdown, isLastSubMenu }) => {
        // Setting classes to specific sub levels
        const ulClass = listLevel === 1 ? firstLevelUl : secondLevelUl;

        // Adding logic for large second level drop down
        if (centerDropdown && listLevel === 1) {
            if (ulClass.indexOf("tablet:left-0") > -1) {
                ulClass.splice(ulClass.indexOf("tablet:left-0"), 1);
                ulClass.push(...["tablet:left-1/2", "tablet:transform", "tablet:-translate-x-1/2"]);
            }
        } else {
            if (ulClass.indexOf("tablet:left-1/2") > -1 && listLevel === 1) {
                ulClass.splice(ulClass.indexOf("tablet:left-1/2"), 1);
                ulClass.splice(ulClass.indexOf("tablet:transform"), 1);
                ulClass.splice(ulClass.indexOf("tablet:-translate-x-1/2"), 1);
                ulClass.push(...["tablet:left-0"]);
            }
        }

        // Adding logic for 3ed level sub menus to not go past the right hand side
        if (isLastSubMenu && listLevel > 1) {
            // Mobile
            if (ulClass.indexOf("mobile:left-4") > -1) {
                ulClass.splice(ulClass.indexOf("mobile:left-4"), 1);
                ulClass.push(...["mobile:right-4"]);
            }
            // Tablet & higher
            if (ulClass.indexOf("tablet:left-0") > -1) {
                ulClass.splice(ulClass.indexOf("tablet:left-0"), 1);
                ulClass.push(...["tablet:right-0"]);
            }
        } else {
            // Mobile
            if (ulClass.indexOf("mobile:right-4") > -1 && listLevel > 1) {
                ulClass.splice(ulClass.indexOf("mobile:right-4"), 1);
                ulClass.push(...["mobile:left-4"]);
            }
            // Tablet && higher
            if (ulClass.indexOf("tablet:right-0") > -1 && listLevel > 1) {
                ulClass.splice(ulClass.indexOf("tablet:right-0"), 1);
                ulClass.push(...["tablet:left-0"]);
            }
        }

        // Creating the navigation items
        const navItems = navigationItems.map((item: INavigationItem, index: number) => {
            // Setting classes to specific sub levels
            let liClass = listLevel === 1 ? [...firstLevelLi] : [...secondLevelLi];
            let aClass = listLevel === 1 ? [...firstLevelLiText] : [...secondLevelLiText];

            // Adding hover effect / active effect
            if (item.hover) {
                if (liClass.indexOf("bg-secondary")) {
                    liClass.splice(liClass.indexOf("bg-secondary"), 1);
                    liClass.splice(liClass.indexOf("text-white"), 1);
                }
                liClass = [...liClass, "text-primary", "bg-gray-100", "bg-opacity-10"];
            }

            // Adding logic for large second level drop down
            if (item.subitems && item.subitems.length > 4) {
                if (liClass.indexOf("tablet:relative") > -1) {
                    liClass.splice(liClass.indexOf("tablet:relative"), 1);
                }
            }

            // Adding logic for large second level drop down
            if (centerDropdown && listLevel === 1) {
                if (liClass.indexOf("tablet:justify-start") > -1) {
                    liClass.splice(liClass.indexOf("tablet:justify-start"), 1);
                    aClass.splice(aClass.indexOf("tablet:justify-start"), 1);
                    liClass.splice(liClass.indexOf("laptop:w-44"), 1);
                    liClass.push(...["tablet:justify-center", "laptop:w-32", "desktop:w-40"]);
                    aClass.push(...["tablet:justify-center", "laptop:w-32", "desktop:w-40"]);
                }
            }

            // Adding logic if the nav item has a description
            if (item.description) {
                if (liClass.indexOf("tablet:h-14") > -1) {
                    liClass.splice(liClass.indexOf("tablet:h-14"), 1);
                }
                liClass = [...liClass, "tablet:h-16"];
            }

            return (
                <li
                    key={index}
                    className={liClass.join(" ")}
                    onClick={(e) => {
                        if (isMobile) {
                            e.stopPropagation();
                            handleOnClick(navigationItems, index);
                        }
                    }}
                    title={item.seo?.title}
                    aria-label={item.seo?.aria_label}
                    onMouseEnter={() => !isMobile && handleOnMouseEnter(navigationItems, index)}
                    onMouseLeave={() => !isMobile && handleOnMouseLeave(navigationItems, index)}
                >
                    <CustomLink url={item.url.toLowerCase()} title={item.seo?.title} ariaLabel={item.seo?.aria_label}>
                        {item.description ? (
                            <>
                                <p className={aClass.join(" ")}>
                                    <JsxParser renderInWrapper={false} jsx={item.title} />
                                </p>
                                <p className={description.join(" ")}>{item.description}</p>
                            </>
                        ) : (
                            <p className={aClass.join(" ")}>
                                <JsxParser renderInWrapper={false} jsx={item.title} />
                            </p>
                        )}
                    </CustomLink>

                    {item.hover && item.subitems && (
                        <ListNavigationItems
                            navigationItems={item.subitems}
                            listLevel={listLevel + 1}
                            centerDropdown={item.subitems.length > 4 && listLevel === 1}
                            isLastSubMenu={
                                (listLevel >= 1 && navigationItems.length - 1 === index) || (isMobile && index % 2 != 0 && listLevel === 1)
                            }
                        />
                    )}
                </li>
            );
        });
        return <ul className={ulClass.join(" ")}>{navItems}</ul>;
    };

    // Close all drop-downs when clicking outside the target element
    useEffect(() => {
        const checkIfClickedOutside = (e: TouchEvent | MouseEvent) => {
            const target = e.target as HTMLElement;

            if (ref.current && !getAllParentNodes(target).includes(ref.current)) {
                const navigationMockState = [...getUpdatedState([...navigationState], [...navigationState], 0, false)];
                dispatch(setProductNavigationItemState(navigationMockState));
            }
        };

        document.addEventListener("click", checkIfClickedOutside);

        return () => {
            document.removeEventListener("click", checkIfClickedOutside);
        };
    }, []);

    return (
        <nav className={wrapper.join(" ")} ref={ref}>
            <ListNavigationItems navigationItems={navigationState} listLevel={1} />
        </nav>
    );
};

export default NavigationItems;
