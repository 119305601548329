import classNames from "classnames";

export const wrapper = classNames(
    // Mobile
    "mobile:flex-col",
    // "mobile:min-h-[150px]",
    "mobile:p-2",
    // Tablet
    "tablet:flex-row",
    "tablet:max-h-[350px]",
    "tablet:p-0",
    // All
    "overflow-hidden",
    "flex",
    "items-center",
    "justify-start",
    "w-full",
    "text-secondary",
    "bg-white",
)

export const image = classNames(
    // Mobile
    "mobile:hidden",
    "mobile:mb-0",
    // Tablet
    "tablet:block",
    "tablet:w-1/2",
    "tablet:h-auto",
    "tablet:mb-14",
    // All
)

export const content = classNames(
    // Mobile
    "mobile:text-center",
    "mobile:px-4",
    "mobile:m-6",
    "mobile:w-full",
    // Tablet
    "tablet:text-left",
    "tablet:pl-20",
    "tablet:w-full",
    "tablet:m-0",
    "tablet:w-1/2",
    // All
)

export const heading = classNames(
    // Mobile
    "mobile:text-[18px]",
    // Tablet
    "tablet:text-[25px]",
    // All
    "mb-2"
)

export const subtext = classNames(
    // Mobile
    "mobile:text-[18px]",
    "mobile:mt-2",
    // Tablet
    "tablet:mt-4",
    "tablet:text-[25px]",
    // All
    "pl-3"
)
export const span = classNames(
    // All
    "text-secondary",
    "font-bold"
)

export const button = classNames(
    // Mobile
    "mobile:w-44",
    "mobile:h-8",
    "mobile:mt-8",
    "mobile:text-base",
    "mobile:font-semibold",
    // Tablet
    "tablet:w-64",
    "tablet:h-12",
    "tablet:mt-12",
    "tablet:text-xl",
    "tablet:font-normal",
    // All
    "bg-secondary",
    "text-white",
    "rounded-lg"
)
