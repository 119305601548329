import { wrapper, p, span } from "./styles/shipping-training-support-classes";
import {Trans, useTranslation} from "react-i18next";

const ShippingTrainingSupport: React.FC = () => {
    const {t} = useTranslation()
    return (
        <div className={wrapper.join(" ")} title={t("index.text-banner-1.seo.title")} aria-label={t("index.text-banner-1.seo.aria-label")}>
            <h4 className={p.join(" ")}>
                <Trans components={{ span: <span className={span.join(" ")} /> }}>index.text-banner-1.content</Trans>
            </h4>
        </div>
    );
};

export default ShippingTrainingSupport;
