export const wrapper = [
    // Mobile
    "mobile:w-full",
    "mobile:flex",
    "mobile:flex-col",    
    // Tablet
    "tablet:w-4/6",
    "tablet:pr-32",
    "tablet:pl-8",
    // All
    "text-white",
];
export const p = [
    // Mobile
    "mobile:text-base",
    "mobile:p-2",
    "mobile:mt-2",
    "mobile:mb-2",
    // Tablet
    "tablet:text-xl",
    // All
    "mt-6",
    "mb-6",
    "inline-block"
];
