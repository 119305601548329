import React from "react";
import {
    button,
    content,
    heading,
    image,
    span,
    subtext,
    wrapper
} from "@/components/home/text-banners/styles/fastest-growing-company";
import ContentHeading from "@/components/content-heading";
import {Trans, useTranslation} from "react-i18next";

const FastestGrowingCompany : React.FC = () => {
    const { t } = useTranslation();

    return <section className={wrapper}>
        <div className={content}>
            <p className={heading}>{t("index.text-banner-3.heading")} </p>
            <ContentHeading
                h="p"
                className="mobile:justify-center tablet:justify-start"
                backdropPosition="mobile:left-2 tablet:left-2"
                fontSize="mobile:text-xl tablet:text-4xl"
                background="bg-primary bg-opacity-40"
                color="text-secondary"
            >
                FASTEST GROWING COMPANIES
            </ContentHeading>
            <div className={"flex items-center justify-start pt-2 mobile:flex-col tablet:flex-row"}>
                <ContentHeading
                    h="p"
                    className="mobile:justify-center tablet:justify-start w-auto"
                    backdropPosition="mobile:left-2 tablet:left-2"
                    fontSize="mobile:text-xl tablet:text-4xl"
                    background="bg-primary bg-opacity-40"
                    color="text-secondary"
                >
                    IN AFRICA,
                </ContentHeading>

                <p className={subtext}>by the <span className={span}>{t("index.text-banner-3.text")}</span></p>
            </div>

            {/*Read more button*/}
            <a target="_blank" href="https://www.hearxgroup.com/blog/hearx-group-recognised-as-one-of-the-fastest-growing-companies-in-africa-by-the-financial-times-and-statista.html">
                <button type="button" className={button}>
                    {t("index.text-banner-3.button")}
                </button>
            </a>
        </div>
            <img
                className={image}
                srcSet={`
						/assets/images/fastest-growing-company/small.webp 420w,
						/assets/images/fastest-growing-company/small.png 420w,
						/assets/images/fastest-growing-company/medium.webp 733w,
						/assets/images/fastest-growing-company/medium.png 733w,
						/assets/images/fastest-growing-company/large.webp 1465w,
						/assets/images/fastest-growing-company/large.png 1465w,
					`}
                sizes="(max-width:736px) 420px, (max-width:1280px) 800px, 1500px"
                src={`/assets/images/fastest-growing-company/small.jpg`}
                alt=""
            />
    </section>
}

export default FastestGrowingCompany
