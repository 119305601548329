import {Trans} from "react-i18next";
import {wrapper, p} from "./styles/content-classes";

const Content: React.FC = () => {
    return (
        <div className={wrapper.join(" ")}>
            <Trans components={{
                p: <p className={p.join(" ")}/>,
                h2: <h2 className={`${p.join(" ")} p-0 mt-0 mb-0`}/>
            }}>index.what-we-do.content</Trans>
        </div>
    );
};

export default Content;
