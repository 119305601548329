import { Trans, useTranslation } from "react-i18next";
// Interfaces
import { IPost } from "@/i18n/json/blog/interfaces";
// Tailwind classes
import {
  actionContainer,
  button,
  buttonText,
  image,
  imageWrapper,
  line,
  summary,
  timeStamp,
  title,
  wrapper,
} from "./styles/blog-item";
import Arrow from "@/components/arrow";
import CustomLink from "../custom-link";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface IBlogItem extends IPost {
  arrows?: React.ReactElement | null;
  collection?: "all" | "latest";
}

const BlogItem: React.FC<IBlogItem> = ({
  id,
  date,
  permalink,
  collection = "latest",
}) => {
  const { t } = useTranslation();
  return (
    <div className={wrapper}>
      <div className={imageWrapper}>
        <LazyLoadImage
          className={image}
          srcSet={`
						/assets/images/blog/${id}/small.webp 420w, 
						/assets/images/blog/${id}/small.jpg 420w,
						/assets/images/blog/${id}/medium.webp 800w, 
						/assets/images/blog/${id}/medium.jpg 800w,
					`}
          sizes="(max-width:736px) 420px, 800px"
          src={`/assets/images/blog/${id}/small.jpg`}
          alt={t(`blog.${collection}.${id}.title`).replace(
            /<[^>]*>\s|<[^>]*>/g,
            " "
          )}
        />
      </div>
      <div>
        <p className={title}>
          <Trans components={{ sup: <sup />, span: <span style={{}} /> }}>
            blog.{collection}.{id}.title
          </Trans>
        </p>
        <hr className={line} />
        <p className={summary}>
          <Trans components={{ sup: <sup /> }}>
            blog.{collection}.{id}.summary
          </Trans>
        </p>
      </div>
      <div className={actionContainer}>
        <CustomLink url={permalink ? permalink : "#"}>
          <button type="button" className={button}>
            <p className={buttonText}>{t("global.buttons.read-more")}</p>
            <Arrow dir="link" color="border-primary" size={2} />
          </button>
        </CustomLink>

        <p className={timeStamp}>{date}</p>
      </div>
    </div>
  );
};

export default BlogItem;
