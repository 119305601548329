import { isMobile } from "react-device-detect";
import { useState } from "react";
import homeData from "@/i18n/json/home/data.json";
import PopupVideo from "@/components/popup-video";
import classNames from "classnames";
// Interfaces
import { IVideo } from "./interfaces/IVideo";
// Tailwind Classes
import { wrapper, container } from "./styles/videos";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Videos: React.FC = () => {
    const videos: IVideo[] = homeData["customer-videos"].videos;
    const [showVideo, setShowVideo] = useState(false);
    const [videoInfo, setVideoInfo] = useState<IVideo["video"] | null>(null);

    const onClickHandler = (videoObj: IVideo["video"]) => {
        setVideoInfo(videoObj);
        setShowVideo(true);
    };

    const closePopup = () => {
        setVideoInfo(null);
        setShowVideo(false);
    };

    return (
        <>
            {showVideo && videoInfo != null && <PopupVideo {...videoInfo} closeHandler={closePopup} />}
            <div className={classNames(wrapper)}>
                {videos.map((item: IVideo, index: number) => {
                    const itemClass = (): string[] => {
                        const classes = [...container];

                        if (index === 2 && !isMobile) {
                            classes.push(...["tablet:row-span-2", "tablet:self-center"]);
                        } else {
                            if (index === 1 && isMobile) {
                                classes.push(...["col-start-2", "row-start-1", "row-end-3", "self-center"]);
                            }
                            if (index === 3 && isMobile) {
                                classes.push(...["col-start-2", "row-start-2", "row-end-4", "self-center"]);
                            }
                        }

                        return classes;
                    };

                    return (
                        <button
                            key={index}
                            onClick={() => {
                                onClickHandler(item.video);
                            }}
                            className={classNames(itemClass())}
                        >
                            <LazyLoadImage
                                srcSet={`
								${item.thumbnail}/small.webp 105w,
								${item.thumbnail}/small.jpg 105w,
								${item.thumbnail}/medium.webp 239w,
								${item.thumbnail}/medium.jpg 239w,
								${item.thumbnail}/large.webp 478w,
								${item.thumbnail}/large.jpg 478w,
							`}
                                sizes={`
								(max-width:736px) 105px, 
								(max-width:1280px) 240px, 
								480px
							`}
                                src={`${item.thumbnail}/small.jpg 105w,`}
                            />
                        </button>
                    );
                })}
            </div>
        </>
    );
};

export default Videos;
