import React from "react";
import {
  buttonDesktop,
  buttonMobile,
  heading,
  img,
  subheading,
  wrapper,
} from "@/components/home/bose-banner/styles/bose-banner";

const BoseBanner: React.FC = () => {
  return (
    <section className={wrapper.join(" ")}>
      <div className={"desktop:w-full tablet:w-[80%] mobile:w-full"}>
        <p className={heading.join(" ")}>
          Are you looking for information on Lexie Hearing?
        </p>
        <p className={subheading.join(" ")}>
          Do you want to learn more about Lexie Powered by Bose?
        </p>
        <div className={buttonDesktop.join(" ")}>
          <button>
            <a
              href={"https://lexiehearing.com/us/compare-hearing-aids"}
              target={"_blank"}
            >
              Visit Lexie
            </a>
          </button>
        </div>
      </div>
      <div className={"tablet:w-[60%] mobile:w-[65%] mobile:mr-8"}>
        <div className={img.join(" ")}>
          <div className={""}>
            <img
              alt={"Bose hearing aids"}
              title={"Bose hearing aids"}
              src={"../../../assets/images/bose/banner/bose-ha.png"}
            />
          </div>
          <div>
            <img
              className={"desktop:pt-10 tablet:pt-8 mobile:pt-2"}
              alt={"Bose logo"}
              title={"Bose logo"}
              src={"../../../assets/images/bose/banner/bose-logo.png"}
            />
          </div>
        </div>
      </div>
      <div className={buttonMobile.join(" ")}>
        <button>
          <a
            href={"https://lexiehearing.com/us/compare-hearing-aids"}
            target={"_blank"}
          >
            Visit Lexie
          </a>
        </button>
      </div>
    </section>
  );
};

export default BoseBanner;
