import ContentHeading from "@/components/content-heading";
import Content from "./content";
import Numbers from "./numbers";
import { useTranslation } from "react-i18next";
// Tailwind Classes
import { wrapper, content } from "./styles/what-we-do-classes";

const WhatWeDo: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className={wrapper.join(" ")} title={t("index.what-we-do.seo.title")} aria-label={t("index.what-we-do.seo.aria-label")}>
            <ContentHeading
                h="p"
                className="mobile:justify-center tablet:justify-start"
                backdropPosition="mobile:left-5"
                fontSize="mobile:text-2xl tablet:text-4xl"
                color="text-white"
            >
                {t("index.what-we-do.title")}
            </ContentHeading>
            <div className={content.join(" ")}>
                <Content />
                <Numbers />
            </div>
        </div>
    );
};

export default WhatWeDo;
