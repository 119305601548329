import {useEffect} from "react";
import globalData from "@/i18n/json/global";
import ContentHeading from "@/components/content-heading";
import {useTranslation} from "react-i18next";
// Tailwind classes
import {wrapper, subheading, rating, p, googleReviews, stars} from "./styles/customer-reviews-classes";

const CustomerReviews: React.FC = () => {
    const {t} = useTranslation();
    // The React hook for document / domContent ready
    useEffect(() => {
        (function (d, s, id) {
            let js: any;
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://embedsocial.com/embedscript/ri.js";
            d.getElementsByTagName("head")[0].appendChild(js);
        })(document, "script", "EmbedSocialReviewsScript");
    });

    return (
        <div className={wrapper.join(" ")} title={t("index.customer-reviews.seo.title")} aria-label={t("index.customer-reviews.seo.aria-label")}>
            <ContentHeading h="p" className="justify-center" backdropPosition="mobile:left-4 tablet:left-8"
                            fontSize="mobile:text-2xl tablet:text-4xl" background="bg-primary bg-opacity-40">
                {t("index.customer-reviews.heading")}
            </ContentHeading>
            <p className={subheading.join(" ")}>{t("index.customer-reviews.subheading")}</p>
            <div className={rating.join(" ")}>
                <p className={p.join(" ")}>
                    {t("index.customer-reviews.rating")} <strong>{globalData.data["google-rating"]}</strong>
                </p>
                <img className={stars.join(" ")} src="/assets/icons/stars.svg" alt="google stars"/>
            </div>
            <div className={`embedsocial-reviews ${googleReviews.join(" ")}`}
                 data-ref={process.env.NEXT_PUBLIC_GOOGLE_REVIEW_ID}/>
        </div>
    );
};

export default CustomerReviews;
