import scss from "@/styles/pages/home/what-we-do.module.scss";

export const wrapper = [
    scss["background-image"],
    // Mobile
    "mobile:pt-8",
    "mobile:pb-8",
    "mobile:pl-8",
    "mobile:pr-8",
    // Tablet
    "tablet:pt-16",
    "tablet:pb-16",
    "tablet:pl-20",
    "tablet:pr-20",
    // All
    "w-full",
    "h-auto",
    "bg-secondary",
    "bg-no-repeat",
];

export const content = [
    // Mobile
    "mobile:flex-col",
    // Tablet
    "tablet:flex-row",
    // All
    "flex",
    "items-center",
    "justify-evenly",
];
