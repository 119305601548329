export const wrapper = [
  // Mobile
  "mobile:mt-5",
  "mobile:h-auto",
  // Tablet
  "tablet:mt-4",
  "tablet:h-28",
  // All
  "relative",
  "w-full",
];
export const container = [
  // Mobile
  "mobile:!grid",
  "mobile:grid-cols-2",
  "mobile:items-center",
  "mobile:justify-center",
  // Tablet
  "tablet:!flex",
  "tablet:items-center",
  "tablet:justify-evenly",
  // All
  "w-full",
  "h-full",
];
const button = [
  "absolute",
  "transform",
  "-translate-y-1/2",
  "z-40",
  "flex",
  "items-center",
  "justify-center",
  "w-[40px]",
  "h-[40px]",
];
export const nextButton = [
  // Mobile
  "mobile:-right-3",
  "mobile:top-1/2",
  // Tablet
  "tablet:right-4",
  "tablet:top-1/2",
  // All
  ...button,
];
export const prevButton = [
  // Mobile
  "mobile:-left-3",
  "mobile:top-1/2",
  // Tablet
  "tablet:left-4",
  "tablet:top-1/2",
  // All
  ...button,
];
export const logoItem = [
  // Mobile
  "mobile:w-8/12",
  "mobile:pl-0",
  "mobile:pr-0",
  "mobile:pt-2",
  "mobile:pb-2",
  "mobile:justify-self-center",
  "mobile:self-center",
  // Tablet
  "tablet:w-auto",
  "tablet:pl-0",
  "tablet:pr-0",
  "tablet:pt-0",
  "tablet:pb-0",
  "tablet:max-w-[150px]",
  // ALl
  "flex",
  "items-center",
  "justify-center",
  "max-w-[180px]",
];
