export const wrapper = [
    // Mobile
    "mobile:p-6",
    // All
    "p-8",
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "bg-gray-100",
];

export const p = [
    // Mobile
    "mobile:text-xl",
    // Tablet
    "tablet:text-xl",
    // All
    "text-gray-400",
    "font-medium",
];
