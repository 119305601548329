import classNames from "classnames";

export const wrapper = classNames("fixed", "top-0", "left-0", "w-full", "h-full", "flex", "items-center", "justify-center", "z-50");
export const overlay = classNames("absolute", "top-0", "left-0", "w-full", "h-full", "bg-gray-900", "opacity-80");
export const closeButton = classNames("absolute", "-right-10", "-top-10");
export const videoContainer = classNames(
    // Mobile
    "mobile:w-9/12",
    "mobile:h-52",
    // Tablet
    "tablet:w-full",
    "tablet:h-full",
    // All
    "relative",
    "z-50",
    "w-full",
    "max-w-screen-lg",
    "h-full",
    "max-h-xl",
    "bg-transparent"
);
export const player = classNames("relative", "w-full", "h-full", "z-20");
export const loading = classNames("absolute", "top-1/2", "left-1/2", "transform", "-translate-x-1/2", "-translate-y-1/2", "text-lg", "text-white","w-full","text-center");
