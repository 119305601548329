export const wrapper = [
    // Mobile
    "mobile:justify-evenly",
    "mobile:pb-4",
    // Tablet
    "tablet:justify-end",
    "tablet:pb-10",
    // All
    "relative",
    "flex",
    "items-center",
    "z-10",
    "w-full",
    "h-auto",
];

export const image = [
    // mobile
    "mobile:h-7",
    "mobile:w-auto",
    // Tablet
    "tablet:h-14",
    "tablet:w-auto",
    "tablet:mr-16",
];
