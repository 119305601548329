import homeDate from "@/i18n/json/home/data.json";
import {useTranslation} from "react-i18next";
import CustomLink from "@/components/custom-link";
// Tailwind Classes
import {wrapper, container, p, logo} from "./styles/featured-logos-classes";
import {LazyLoadImage} from "react-lazy-load-image-component";
//Interfaces
import {IFeaturedImages} from "./interfaces/IFeaturedImages"

const FeaturedLogos: React.FC = () => {
    const {t} = useTranslation();
    const images = [...homeDate["featured-in-images"]];

    return (
        <div className={wrapper.join(" ")}>
            <p className={p.join(" ")}>{t("index.featured")}</p>
            <div className={container.join(" ")}>
                {images.map((item: IFeaturedImages, index: number) => {
                    return (
                        <CustomLink className={logo.join(" ")} url={item.url} key={index} external={true}>
                            <LazyLoadImage
                                srcSet={`
									${item.image.url}/small.webp ${item.image.small}w,
									${item.image.url}/small.png ${item.image.small}w,
									${item.image.url}/medium.webp ${item.image.medium}w,
									${item.image.url}/medium.png ${item.image.medium}w,
									${item.image.url}/large.webp ${item.image.large}w,
									${item.image.url}/large.png ${item.image.large}w,
								`}
                                sizes={`
									(max-width:736px) ${item.image.small}px, 
									(max-width:1024px) ${item.image.medium}px,
									${item.image.large}px
								`}
                                width={item.width}
                                height={item.height}
                                src={`${item.image.url}/small.png`}
                                alt={item.alt}
                                title={item.title}
                            />
                        </CustomLink>
                    );
                })}
            </div>
        </div>
    );
};

export default FeaturedLogos;
