import classNames from "classnames";
import scss from "@/styles/pages/home/text-banners.module.scss";

export const wrapper = classNames(
    // Mobile
	"mobile:items-center",
	"mobile:justify-center",
	"mobile:p-10",
    // Tablet
	"tablet:p-20",
	"tablet:items-start",
	"tablet:justify-start",
    // All
	"flex",
	"flex-col",
    scss["wrapper"],
	"bg-white"
);
export const p = classNames(
    // Mobile
    "mobile:text-lg",
    "mobile:text-left",
    "mobile:mt-4",
    "mobile:mb-4",
    // Tablet
    "tablet:text-2xl",
    "tablet:text-left",
	"tablet:mt-10",
    "tablet:mb-10",
    // All
    "max-w-screen-md",
    "font-normal"
);
export const button = classNames(
    // Mobile
    "mobile:w-44",
    "mobile:h-8",
    "mobile:text-base",
    "mobile:font-semibold",
    // Tablet
    "tablet:w-64",
    "tablet:h-12",
    "tablet:text-xl",
    "tablet:font-normal",
    // All
    "bg-secondary",
    "text-white",
    "rounded-lg"
);
