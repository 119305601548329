import { useTranslation } from "react-i18next";
import { wrapper, p } from "./styles/our-clients-classes";
import OurClientsCarousel from "./our-clients-carousel";

const OurClients: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className={wrapper.join(" ")}>
            <p className={p.join(" ")}>{t("index.our-clients")}</p>
            <OurClientsCarousel />
        </div>
    );
};

export default OurClients;
