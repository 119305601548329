export const wrapper = [
    // Mobile
    "mobile:w-full",
    // Tablet
    "tablet:w-1/3",
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "text-white",
];
export const title = [
    // Mobile
    "text-xl",
    "mb-5",
];
export const numbers = [
    // Mobile
    "mobile:text-6xl",
    // Tablet
    "tablet:text-7xl",
    "font-bold",
    "mb-10",
    "font-medium",
];
