export const wrapper = ["bg-white","pb-5"];
export const subheading = [
    // Mobile
    "mobile:text-base",
    "mobile:mt-6",
    // Tablet
    "tablet:text-xl",
    "tablet:mt-8",
    // All
    "text-center",
];
export const rating = ["flex", "items-center", "justify-center", "mt-2"];
export const p = [
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-xl",
    // All
    "mr-2",
];
export const googleReviews = [
    // All
    "w-11/12",
    "m-auto",
    "overflow-hidden",
    "mb-5",
];

export const stars = [
    // Mobile
    "mobile:h-4",
    // Tablet
    "tablet:h-6",
];
