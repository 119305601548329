import React  from "react";
import { IPost } from "@/i18n/json/blog/interfaces/index";
import BlogItem from "./blog-item";
import Arrow from "../arrow";
// Tailwind classes
import { wrapper, nextButton, prevButton } from "./styles/latest-blog-carousel";

import Slider from "react-slick"

const LatestBlogCarousel: React.FC<{ data: IPost[] }> = ({ data }) => {
    const latestBlogIndexes = [0, 1, 2];

    const CustomArrow: React.FC<{ dir: "left" | "right", onClick?: () => void }> = ({dir, onClick}) => {
        return <button type={"button"} className={`${dir === "right" ? prevButton : nextButton}`}>
            <Arrow dir={dir} color={"border-white"} onClick={onClick}/>
        </button>
    }

    const settings = {
        centerMode: true,
        infinite: true,
        arrows:true,
        centerPadding: "0px",
        slidesToShow: 3,
        speed: 500,
        nextArrow: <CustomArrow dir={"left"}/>,
        prevArrow: <CustomArrow dir={"right"}/>,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
        ]
    }

    return (
        <>
            <div className={wrapper}>
                <Slider {...settings}>
                    {latestBlogIndexes.map((item: number, index: number) => {
                        return <div key={index}><BlogItem {...data[item]} /></div>;
                    })}
                </Slider>
            </div>
        </>
    );
};

export default LatestBlogCarousel;
