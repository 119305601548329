import ContentHeading from "@/components/content-heading";
import { useTranslation, Trans } from "react-i18next";
import { wrapper, subheading, description, link, disclaimer } from "./styles";

import { RequestDemoForm } from "@/components/forms/";

const GetInTouch: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section
      className={wrapper}
      title={t("forms.get-in-touch.seo.title")}
      aria-label={t("forms.get-in-touch.seo.aria-label")}
    >
      <ContentHeading
        h="p"
        className="justify-center"
        backdropPosition="left-4"
        fontSize="mobile:text-2xl tablet:text-4xl"
        background="bg-primary bg-opacity-40"
      >
        {t("forms.get-in-touch.heading")}
      </ContentHeading>
      <RequestDemoForm
        formClassName="GA4-LeadConversion"
        formId="homepage-get-in-touch-form"
        showPromo
      />
      <p className={disclaimer}>{t("global.hear-usa-disclaimer")}</p>
    </section>
  );
};

export default GetInTouch;
