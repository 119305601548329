import classNames from "classnames";

export const wrapper = classNames("relative")
export const videoContainer = classNames("[z-index:-1] bg-secondary absolute top-0 left-0 w-full h-full")
export const videoWrapper = classNames("opacity-0", "fixed", "top-[calc(50%_-_8rem)]", "left-1/2", "transform", "-translate-x-1/2", "-translate-y-1/2", "w-[120%]", "h-[120%]", "max-h-[120vh]", "z-[1]", "transition-all", "duration-700", "ease-linear");
export const backdrop = classNames(
    // All
    "absolute",
    "w-full",
    "h-full",
    "bg-[color:#404040]",
    "bg-opacity-[52%]",
    "z-10",
    "top-0",
    "left-0",
);

export const video = [
    "absolute",
    "left-1/2",
    "top-1/2",
    "transform",
    "-translate-y-1/2",
    "-translate-x-1/2",
    "w-full",
    "h-auto",
    "transition-all",
    "duration-700",
]