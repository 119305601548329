export const wrapper = [
	// Mobile
	"mobile:pt-8",
	"mobile:pb-8",
	"mobile:pl-2",
	"mobile:pr-2",
	// Tablet
	"tablet:pt-8",
	"tablet:pb-8",
	"tablet:pl-8",
	"tablet:pr-8",
    // All
    "flex",
    "flex-col",
    "items-center",
    "justify-center",
    "bg-gray-100",
];

export const p = [
    // Mobile
    "mobile:text-xl",
    // Tablet
    "tablet:text-xl",
    // All
    "text-gray-400",
    "font-medium",
];

export const container = [
    // Mobile
    "mobile:flex",
    "mobile:flex-wrap",
    "mobile:justify-center",
    // Tablet
    "tablet:grid",
    "tablet:grid-cols-5",
    // All
    "w-full",
    "items-center",
    "justify-items-center",
];

export const logo = [
    // Mobile
    "mobile:m-3",
    "mobile:w-1/4",
    // Tablet
    "tablet:m-5",
    "tablet:w-auto",
];
