import loadable from "@loadable/component";
import React, {ReactText, useEffect, useRef, useState} from "react";
import homeData from "@/i18n/json/home";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faCircle} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {
    activeImage,
    activeImageContainer,
    activeProduct,
    productButton,
    productHeading,
    subHeading
} from "@/components/home/our-products/styles/product-items-classes";
import {Trans, useTranslation} from "react-i18next";
import CustomLink from "@/components/custom-link";
import Arrow from "@/components/arrow";
import {nextButton, prevButton} from "@/components/home/our-products/styles/product-carousel-classes";
import {isMobile} from "react-device-detect";
import {dotsClasses} from "@/components/home/our-products/styles/product-carousel-dot-classes";
import {useRouter} from "next/dist/client/router";
// Lazy load / Defer components
import Slider from "react-slick"

// const Button = loadable(() => import("@/components/button/button"));

interface IProductCarousel {
    settings: {};
    className?: string;
    dotColor?: string;
}

const FinalPC: React.FC<IProductCarousel> = ({settings, className, dotColor}) => {
    const router = useRouter();
    const visitedProduct = router.asPath.replace(/.*#/, "")
    const carouselRef = useRef<HTMLDivElement>(null)
    const products = homeData.data["our-products"] as [];
    // To link the two sliders
    const [productSlide, setProductSlide] = useState<Slider | undefined>(undefined);
    const [productDescriptionSlide, setProductDescriptionSlide] = useState(undefined);

    //  Tailwind CSS
    const slideItem = [
        // Mobile
        "mobile:items-center",
        // Laptop & higher
        "laptop:items-center",
        // ALl
        "flex",
        "flex-col",
        "justify-center",
        "text-center",
        "relative",
        "w-full",
        "h-full",
        "transition-all",
        "[outline:none]"
    ];

    const descriptionWrapper = [
        // Mobile
        "mobile:h-auto",
        "mobile:w-full",
        // Laptop & higher
        "laptop:w-1/2",
        "laptop:h-auto",
        "laptop:text-lg",
        // All
        "m-auto",
        "h-44",
        "relative",
    ];

    const description = [
        // Mobile
        "mobile:text-base",
        "mobile:w-full",
        "mobile:pl-3",
        "mobile:pr-3",
        // Laptop & higher
        "laptop:text-xl",
        "laptop:w-5/6",
        // All
        "text-xl",
        "ml-auto",
        "mr-auto",
    ];

    const imgWrapper = [
        // Mobile
        "mobile:h-auto",
        "mobile:mt-5",
        "mobile:mb-0",
        "mobile:pl-0",
        "mobile:pr-0",
        // Laptop
        "laptop:mt-5",
        "laptop:mb-2",
        "laptop:h-96",
        "laptop:pl-5",
        "laptop:pr-5",
        // All
        "flex",
        "items-center",
        "justify-center",
        "w-full",
    ];

    const img = [
        // Mobile
        "mobile:w-8/12",
        "mobile:h-auto",
        // Laptop & higher
        "laptop:w-auto",
        "laptop:h-auto",
        // All
        "max-w-full",
        "max-h-full",
        "m-auto",
    ];

    const dots = [
        // All
        "flex",
        "items-center",
        "justify-center",
    ];


    // Reusable Components
// Interface for the carousel custom arrows
    interface IArrow {
        direction: "left" | "right";
        onClick?: () => void;
    }

    // Custom arrow component
    const CustomArrow: React.FC<IArrow> = ({direction, onClick}) => {
        // Tailwind CSS
        const rightArrow = [
            // Mobile
            "mobile:-right-5",
            "mobile:-translate-x-10",
            "mobile:top-2/3",
            // Laptop & higher
            "laptop:left-2/3",
            "laptop:top-[60%]",
            "laptop:-translate-x-2",
            // All
            "absolute",
            "transform -translate-y-1/2",
            "z-40",
            "focus:outline-none",
            "flex",
            "items-center",
            "justify-center",
            "w-[40px]",
            "h-[40px]",
        ];

        const leftArrow = [
            // Mobile
            "mobile:left-5",
            "mobile:top-2/3",
            // Laptop & higher
            "laptop:left-1/3",
            "laptop:top-[60%]",
            "laptop:-translate-x-1/2",
            // All
            "absolute",
            "transform -translate-y-1/2",
            "z-40",
            "focus:outline-none",
            "flex",
            "items-center",
            "justify-center",
            "w-[40px]",
            "h-[40px]",
        ];
        return (
            <button type={"button"} className={`${direction === "right" ? leftArrow.join(" ") : rightArrow.join(" ")}`}  onClick={onClick}>
                <Arrow dir={direction} color={"border-secondary"}/>
            </button>
        );
    };

    const goToVisitedSlideItem = () => {
        const carousel: HTMLDivElement | null = carouselRef.current && carouselRef.current
        const product = visitedProduct === "" ? "0" : visitedProduct.replace("prod-", "")

        if (carousel) {
            if (productSlide) {
                productSlide.slickGoTo(parseInt(product))
            }
        }

    }

    // Function that will trigger before the slide changes
    const beforeChange = (oldIndex: number, newIndex: number) => {

        const carousel: HTMLDivElement | null = carouselRef.current && carouselRef.current

        if (carousel) {
            carousel.querySelectorAll(".slick-slide > div div.slide-item").forEach((elm) => {
                elm.querySelectorAll("*:not(.image, img)").forEach((elm) => {
                    elm.classList.add("opacity-0");
                });

                elm.querySelector(".image")?.classList.add("!opacity-50");
                elm.querySelector(".image img")?.classList.remove("laptop:!w-auto");
                elm.querySelector(".image img")?.classList.add("laptop:!w-3/4");
            });

            carousel.querySelectorAll(`.slick-slide[data-index="${newIndex}"] *:not(.image, img)`).forEach((elm) => {
                elm.classList.remove("opacity-0");
            });

            carousel.querySelector(`.slick-slide[data-index="${newIndex}"] .image`)?.classList.remove("!opacity-50");
            carousel.querySelector(`.slick-slide[data-index="${newIndex}"] .image img`)?.classList.add("laptop:!w-auto");
            carousel.querySelector(`.slick-slide[data-index="${newIndex}"] .image img`)?.classList.remove("laptop:!w-3/4");
        }
    };

    // Function that triggers when the slider is created
    const onInit = () => {
        const carousel: HTMLDivElement | null = carouselRef.current && carouselRef.current
        if (carousel) {
            carousel.querySelectorAll(".slick-slide:not(.slick-current) > div div.slide-item").forEach((elm) => {
                elm.classList.add("!outline-0")
                elm.querySelectorAll("*:not(.image, img)").forEach((elm) => {
                    elm.classList.add("opacity-0");
                });

                elm.querySelector(".image")?.classList.add("!opacity-50");
                elm.querySelector(".image img")?.classList.remove("laptop:!w-auto");
                elm.querySelector(".image img")?.classList.add("laptop:!w-3/4");
            });
        }
    };

    // Slider settings
    settings = {
        ...settings,
        dotsClass: dots.join(" "),
        asNavFor: productDescriptionSlide,
        ref: (slider: any) => {
            setProductSlide(slider);
        },
        beforeChange: (oldIndex: number, newIndex: number) => beforeChange(oldIndex, newIndex),
        onInit: () => {
            onInit();
            if (productSlide) {
                goToVisitedSlideItem()
            }
        },
        onReInit: () => onInit(),
        nextArrow: <CustomArrow direction="left"/>,
        prevArrow: <CustomArrow direction="right"/>,
        appendDots: (dots: any) => {
            return (
                <ul>
                    {dots.map((item: any) => {
                        const dotClass = [...dotsClasses]
                        if (item.props.className === "slick-active") {
                            if (dotClass.indexOf("bg-gray-200") !== -1) {
                                dotClass.splice(dotClass.indexOf("bg-gray-200"));
                                dotClass.push(...["bg-primary"]);
                            }
                        }
                        return (
                            <li key={item.key} className={`${dotClass.join(" ")} m-1`}/>
                        );
                    })}
                </ul>
            );
        },
    };

    // Description slider
    const descSettings = {
        className: "mobile:!mt-4 laptop:!mt-10",
        asNavFor: productSlide,
        ref: (slider: any) => {
            setProductDescriptionSlide(slider);
        },
        arrows: false,
        dots: false,
        centerMode: false,
        slidesToShow: 1,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                },
            },
        ],
    };
    // List of the products
    const listProducts = () => {
        return products.map((item: any, i: number) => {
            const containerClasses = activeProduct;
            const imageContainer = activeImageContainer;
            const imageClasses = activeImage;
            const {t} = useTranslation();

            return (
                <div className={`${slideItem.join("  ")} slide-item`} key={i} data-component={item.product}>
                    <h5 className={productHeading.join(" ")}>
                        <Trans components={{
                            sup: <sup className='text-[50%] -top-4, mobile:-top-2'/>
                        }}>index.our-products.{item.product}.heading</Trans>
                    </h5>
                    <h6 className={subHeading.join(" ")}>{t(`index.our-products.${item.product}.subheading`)}</h6>
                    <CustomLink url={item.url}>
                        <button className={productButton.join(" ")} type="button" onClick={(e) => {
                            const url = window.location.pathname.replace(/#.*/g, "")
                            e.stopPropagation();
                            window.location.href = `${url}#prod-${i}`
                        }}>
                            {t(`index.our-products.${item.product}.button`)}
                        </button>
                    </CustomLink>
                    <div className={`${imgWrapper.join(" ")} image`}>
                        <img
                            className={img.join(" ")}
                            srcSet={`
						${item.image.url}/small.webp 420w, 
						${item.image.url}/small.png 420w,
						${item.image.url}/medium.webp 800w, 
						${item.image.url}/medium.png 800w
					`}
                            sizes={`
						(max-width:736px) 420px, 
						(max-width:1600px) 800px, 
						${item.image.width}px
					`}
                            src={`${item.image.url}/small.png`}
                            alt={item.image.alt}
                            title={item.image.title}
                            width={`${item.image.width}px`}
                            height={`${item.image.height}px`}
                        />
                    </div>
                </div>
            );
        });
    };

    // List the product description
    const listProductDescription = () => {
        return products.map((item: any, i: number) => {
            return (
                <div key={i} className={slideItem.join(" ") + " slide-item-description pl-5 pr-5"}>
                    <div className={descriptionWrapper.join(" ")}>
                        <Trans components={{
                            br: !isMobile ? <br/> : <span/>
                        }}>index.our-products.{item.product}.description</Trans>
                    </div>
                </div>
            );
        });
    };

    useEffect(() => {
        if (productSlide) {
            goToVisitedSlideItem()
        }
    })

    // The component
    return (
        <div className="mt-10 product-carousel" ref={carouselRef}>
            <Slider {...settings}>{listProducts()}</Slider>
            <Slider {...descSettings}>{listProductDescription()}</Slider>
        </div>
    );
};

export default FinalPC;