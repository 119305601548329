import NavigationItems from "./navigation-items";
// Tailwind Classes
import { wrapper } from "./styles/product-navigation-classes";

const ProductNavigation: React.FC = () => {
    return (
        <div className={wrapper.join(" ")}>
            <NavigationItems />
        </div>
    );
};

export default ProductNavigation;
