export const wrapper = [
    // Mobile
    "mobile:grid",
    "mobile:grid-cols-2",
    // Tablet
    "laptop:grid",
    "laptop:grid-cols-3",
];
export const container = [
    // Mobile
    "mobile:w-20",
    "mobile:h-20",
    // Larger mobile
    "mobile-lg:w-32",
    "mobile-lg:h-32",
    // Tablet
    "tablet:w-52",
    "tablet:h-52",
    // All
    "bg-center",
    "bg-cover",
    "overflow-hidden",
];
