import classNames from "classnames";

export const wrapper = classNames(
    // Mobile
    "mobile:flex-col",
    "mobile:p-5",
    // Tablet
    "tablet:flex-row",
    "tablet:p-10",
    // All
    "flex",
    "items-center",
    "justify-evenly",
    "text-secondary",
    "bg-white"
);
export const content = classNames(
    // Mobile
    "mobile:text-lg",
    "mobile:text-center",
    // Tablet
    "tablet:text-3xl",
    "tablet:text-left"
);
export const button = classNames(
    // Mobile
    "mobile:w-44",
    "mobile:h-8",
    "mobile:text-base",
    "mobile:font-semibold",
    // Tablet
    "tablet:w-64",
    "tablet:h-12",
    "tablet:text-xl",
    "tablet:font-normal",
    // All
    "bg-secondary",
    "text-white",
    "rounded-lg"
);
