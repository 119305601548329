export const dotContainer = [
    // Mobile
    "mobile:mb-6",
    "mobile:w-28",
    // Tablet
    "tablet:mb-10",
    "tablet:w-32",
    // All
    "flex",
    "items-center",
    "justify-evenly",
];
export const dotsClasses = [
    // Mobile
    "mobile:w-2",
    "mobile:h-2",
    // Tablet
    "tablet:w-3",
    "tablet:h-3",
    // All
    "rounded-full",
    "bg-gray-200",
];
