import classNames from "classnames";

export const wrapper = classNames(
    // Mobile
    "mobile:max-w-[280px]",
    "mobile:min-h-[700px]",
    "mobile:m-auto",

    // laptop
    "mobile-lg:ml-auto",
    "mobile-lg:max-w-[380px]",
    "mobile-lg:mr-auto",
    // All
    "w-full",
    "flex",
    "flex-col",
    "items-start",
    "justify-start",
    "relative",
    "flex-1",
    "p-4",
    "bg-white",
    "rounded-xl",
    "text-secondary"
);

export const imageWrapper = classNames(
    // Mobile
    "mobile:h-32",
    // Tablet
    "tablet:h-56",
    // All
    "flex",
    "items-center",
    "justify-center",
    "w-full",
    "overflow-hidden",
    "rounded-xl"
);
export const image = classNames("w-auto", "h-[100%]", "max-w-none");
export const title = classNames(
    // Mobile
    "mobile:text-lg", 
    // Tablet
    "tablet:text-lg",
    // All
    "text-center",
    "font-extrabold",
    "mt-4",
    "mb-2",
    "text-secondary"
    
);
export const line = classNames("w-4/6", "h-0", "border", "border-primary", "bg-primary", "ml-auto", "mr-auto", "mt-2", "mb-2");
export const summary = classNames(
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-lg",
    // All
    "font-light",
    "mb-5",
    "text-secondary"
);
export const actionContainer = classNames("flex", "items-center", "justify-between", "w-full", "mt-auto");
export const button = classNames("flex", "items-center", "text-base", "font-semibold", "text-primary");
export const buttonText = classNames("mr-2");
export const timeStamp = classNames("font-light", "text-secondary");
