import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";
import { LazyLoadComponent } from "react-lazy-load-image-component";
// Tailwind classes
import { wrapper, title, numbers } from "./styles/numbers-classes";
// import

const Numbers = () => {
  const { t } = useTranslation();
  const [wasVisible, setWasVisible] = useState(false);

  const TestNumbers: React.FC = () => {
    const { number } = useSpring({
      from: { number: 0 },
      number: 2000000,
      config: { duration: 4000 },
      onRest: () => {
        setWasVisible(true);
      },
    });

    return wasVisible ? (
      <div>{new Intl.NumberFormat().format(2000000)}</div>
    ) : (
      <animated.div>
        {number.to((n) => new Intl.NumberFormat().format(Math.floor(n)))}
      </animated.div>
    );
  };

  const CountryNumbers: React.FC = () => {
    const { number } = useSpring({
      from: { number: 0 },
      number: 191,
      config: { duration: 4000 },
      onRest: () => {
        setWasVisible(true);
      },
    });

    return wasVisible ? (
      <div>{new Intl.NumberFormat().format(191)}</div>
    ) : (
      <animated.div>
        {number.to((n) => new Intl.NumberFormat().format(Math.floor(n)))}
      </animated.div>
    );
  };

  return (
    <div className={wrapper.join(" ")}>
      <p className={title.join(" ")}>
        {t("index.what-we-do.numbers.tests.title")}
      </p>
      <div className={numbers.join(" ")}>
        <LazyLoadComponent>
          <TestNumbers />
        </LazyLoadComponent>
      </div>
      <p className={title.join(" ")}>
        {t("index.what-we-do.numbers.countries.title")}
      </p>
      <div className={numbers.join(" ")}>
        <LazyLoadComponent>
          <CountryNumbers />
        </LazyLoadComponent>
      </div>
    </div>
  );
};

export default Numbers;
