export const wrapper = [
    // Mobile
    "mobile:flex-col",
    "mobile:text-center",
    "mobile:px-6",
    "mobile:py-8",
    // Tablet
    "tablet:flex-row",
    "tablet:text-left",
    "tablet:pr-0",
    "tablet:pl-16",
    "tablet:py-2",
    //Desktop
    "desktop:py-0",
    // All
    "flex",
    "items-center",
    // "justify-end",
    "justify-items-end",
    "bg-white",
];

export const heading = [
    // Mobile
    "mobile:text-[22px]",
    // Tablet
    "tablet:text-4xl",
    //Desktop
    "desktop:text-[44px]",
    // All
    "text-[#393939]",
    "font-extrabold",
];

export const subheading = [
    // Mobile
    "mobile:text-[18px]",
    "mobile:mt-2",
    // Tablet
    "tablet:text-2xl",
    "tablet:mt-6",
    //Desktop
    "desktop:text-[28px]",
    // All
    "text-[#393939]",
];

export const buttonDesktop = [
    // Mobile
    "mobile:hidden",
    "mobile:w-[60%]",
    // Tablet
    "tablet:block",
    "tablet:w-[35%]",
    // All
    "bg-[#393939]",
    "text-white",
    "text-center",
    "text-[20px]",
    "rounded-lg",
    "p-2",
    "mt-10",
];

export const buttonMobile = [
    // Mobile
    "mobile:block",
    "mobile:w-[60%]",
    "mobile:text-[16px]",
    "mobile:mt-6",
    // Tablet
    "tablet:hidden",
    "tablet:w-[35%]",
    "tablet:text-[20px]",
    "tablet:mt-10",
    // All
    "bg-[#393939]",
    "text-white",
    "text-center",
    "rounded-lg",
    "p-2",
];

export const img = [
    // Mobile
    "mobile:mt-4",
    "mobile:ml-0",
    // "mobile:w-full",
    // Tablet
    "tablet:mt-0",
    "tablet:ml-0",
    "tablet:w-[85%]",
    //Desktop
    "desktop:w-[80%]",
    // All
    "flex",
    "flex-row",
    "items-center",
    "justify-end",
];

