import ContentHeading from "@/components/content-heading";
import NewProductCarousel from "./new-product-carousel";
// Tailwind Classes
import { wrapper } from "./styles/our-products-classes";
// SCSS
import scss from "@/styles/pages/home/our-products.module.scss";
import FinalPC from "@/components/home/our-products/final";

const OurProducts: React.FC = () => {
    return (
        <div id="product-range" className={`${scss["wrapper"]} ${wrapper.join(" ")}`}>
            <ContentHeading
                h="p"
                className="justify-center"
                backdropPosition="mobile:left-4 tablet:left-8"
                fontSize="mobile:text-2xl tablet:text-4xl"
                background="bg-primary bg-opacity-40"
            >
                Our products
            </ContentHeading>
            <FinalPC settings={{
                centerMode: true,
                slidesToShow: 3,
                arrows: true,
                showArrows: true,
                dots: true,
                infiniteLoop: true,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode:false,
                            infiniteLoop:false
                        },
                    },
                ],
            }}
                     dotColor="text-primary"/>
        </div>
    );
};

export default OurProducts;
