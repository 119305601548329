import Image from "next/image";
import styles from "./SlidesHero.module.scss";
import WantMoreInfo from "@/components/forms/want-more-info";
import { HeroPromoBanner } from "../hero-promo-banner/hero-promo-banner";
import Badges from "../video-header/badges";
import { Trans, useTranslation } from "react-i18next";
import { ReactNode } from "react";
import {
  title,
  heading,
  button,
  enquire,
} from "../video-header/styles/content-classes";
import CustomLink from "@/components/custom-link";
import { Slide } from "react-slideshow-image";

const IMAGE_SCALE = 0.5;

export const SlidesHero: React.FC<{ left?: ReactNode }> = ({ left }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.left}>
          <>
            <div className={"pt-6"}></div>
            <p className={styles.title}>{t("index.video-header.title")}</p>
            <h1 className={styles.heading}>
              <Trans components={{ br: <br /> }}>
                index.video-header.sub-title
              </Trans>
            </h1>
            <button className={styles.button} type="button">
              <CustomLink url="#product-range">
                {t("index.video-header.button")}
              </CustomLink>
            </button>
            <div className={styles.enquire}>
              <CustomLink url="mailto:sales@hearxgroup.com">
                {t("index.video-header.enquire")}
              </CustomLink>
              <img
                className="ml-2"
                width="18px"
                height="14px"
                src="assets/icons/pointer.svg"
                alt="pointer"
              />
            </div>
          </>
        </div>
        <div className={styles.right}></div>
      </div>
      <Badges className={styles.badges} />
      <HeroPromoBanner />
      <div className={styles.slidesWrapper}>
        <Slide
          cssClass={styles.slides}
          arrows={false}
          autoplay={true}
          indicators={false}
          canSwipe={false}
          duration={6000}
          transitionDuration={750}
          easing="cubic-out"
        >
          {SLIDES.map((src, index) => (
            <div
              key={index}
              className={styles.slide}
              style={{ backgroundImage: `url('${src}')` }}
            />
          ))}
        </Slide>
      </div>
    </div>
  );
};

const SLIDES = [
  "/assets/images/pages/home/slider-hero/slide-1.png",
  "/assets/images/pages/home/slider-hero/slide-2.png",
  "/assets/images/pages/home/slider-hero/slide-3.png",
  "/assets/images/pages/home/slider-hero/slide-4.png",
  "/assets/images/pages/home/slider-hero/slide-5.png",
  "/assets/images/pages/home/slider-hero/slide-6.png",
  "/assets/images/pages/home/slider-hero/slide-7.png",
  "/assets/images/pages/home/slider-hero/slide-8.png",
];
