export const wrapper = [
	// Mobile
	"mobile:pl-4",
	"mobile:pr-4",
    // All
    "flex",
    "items-center",
    "justify-center",
    "w-full",
    "h-24",
    "bg-secondary",
];

export const p = [
    // Mobile
    "mobile:text-base",
    // Tablet
    "tablet:text-2xl",
    // All
    "text-white",
    "font-semibold",
	"text-center"
];

export const span = [
	// Tablet
	"tablet:pl-2",
    "tablet:pr-2",
    "tablet:ml-2",
    "tablet:mr-2",
    // All
    "text-primary",
];
