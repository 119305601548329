import { useEffect, useState } from "react";
import ContentHeading from "@/components/content-heading";
import Videos from "./videos";
// I18N
import { useTranslation } from "react-i18next";
// Tailwind Styles
import { wrapper, content, button } from "./styles";

const CustomerVideos: React.FC = () => {
    const { t } = useTranslation();
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        if (!contentLoaded) {
            setContentLoaded(true);
        }
    }, [contentLoaded]);

    return (
        <section className={wrapper} title={t("index.customer-videos.seo.title")} aria-label={t("index.customer-videos.seo.aria-label")}>
            {contentLoaded && <Videos />}
            <div className={content}>
                <ContentHeading h="p" color="text-white" fontSize="mobile:text-xl mobile-lg:text-2xl tablet:text-4xl" backdropPosition="left-4">
                    {t("index.customer-videos.heading")}
                </ContentHeading>
                <a href="#product-range">
                    <button className={button} type="button">
                        {t("index.customer-videos.button")}
                    </button>
                </a>
            </div>
        </section>
    );
};

export default CustomerVideos;
