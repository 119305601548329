import React, {useEffect, useRef} from "react";
import {isMobile} from "react-device-detect";
import homeData from "@/i18n/json/home";
// Components
import Arrow from "@/components/arrow";
// Tailwind styles
import {wrapper, container, nextButton, prevButton, logoItem} from "./styles/our-clients-carousel-classes";
import {LazyLoadComponent, LazyLoadImage} from "react-lazy-load-image-component";
import Slider from "react-slick";
// Interfaces
import {IClientItem} from "@/components/home/our-clients/interfaces/IClientItem";

const OurClientsCarousel: React.FC = () => {
    const carouselRef = useRef<HTMLDivElement>(null)
    const products = homeData.data["our-clients"] as IClientItem[];
    const desktopSlideLength = Math.ceil(products.length / 5)
    const mobileSlideLength = Math.ceil(products.length / 4)

    const getSlides = () => {
        const slideLength = isMobile ? mobileSlideLength : desktopSlideLength
        const slideImages = isMobile ? 4 : 5;
        const returnArray = Array.from(Array(slideLength).keys()).map((item, index) => {
            return products.filter((prodItem, prodIndex) => {
                if (prodIndex >= (item * slideImages) && prodIndex < ((item + 1) * slideImages)) {
                    return prodItem
                }
            })
        })

        if (returnArray[returnArray.length - 1].length !== slideImages) {
            products.filter((item, index) => {
                if ((index) <= (slideImages - returnArray[returnArray.length - 1].length)) {
                    returnArray[returnArray.length - 1].push(item);
                }
            })
        }

        return returnArray
    }

    const CustomArrow: React.FC<{ dir: "left" | "right", onClick?: () => void }> = ({dir, onClick}) => {
        return <button type={"button"} className={`${dir === "right" ? prevButton.join(" ") : nextButton.join(" ")}`} onClick={onClick}>
            <Arrow dir={dir} color={"border-secondary"}/>
        </button>
    }

    const onInit = () =>{
        if(carouselRef.current){
            const slider:HTMLElement | null = carouselRef.current.querySelector(".slick-track");
            if(slider){
                slider.style.display = "flex"
                slider.style.alignItems = "center";
                slider.style.justifyContent = "center";
            }
        }
    }

    const settings = {
        centerMode: true,
        infinite: true,
        dots: false,
        onInit:onInit,
        onReInit:onInit,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 500,
        nextArrow: <CustomArrow dir={"left"}/>,
        prevArrow: <CustomArrow dir={"right"}/>,
    }

    return <LazyLoadComponent>
        <div className={wrapper.join(" ")} ref={carouselRef}>
            <Slider {...settings}>
                {getSlides().map((item, index) => {
                    return <div className={container.join(" ")} key={index}>
                        {item.map((prodItem, index) => {
                            return <LogoItem key={index} {...prodItem} />;
                        })}
                    </div>
                })}
            </Slider>
        </div>
    </LazyLoadComponent>
};

const LogoItem: React.FC<IClientItem> = (props) => {
    const {url, alt, sizes, title} = props;
    return (
        <div className={logoItem.join(" ")}>
            <img
                srcSet={`
					${url}/small.png ${sizes.small}w,
					${url}/small.webp ${sizes.small}w,
					${url}/medium.png ${sizes.medium}w,
					${url}/medium.webp ${sizes.medium}w,
				`}
                sizes={`(max-width:420px) ${sizes.small}px, ${sizes.medium}px`}
                src={`${url}/small.png`}
                alt={alt}
                title={title}
            />
        </div>
    );
};

export default OurClientsCarousel;
