export const wrapper = [
    // Mobile
	"mobile:pl-5",
    "mobile:pr-5",
    // Tablet
	"tablet:pl-8",
    "tablet:pr-8",
    // All
    "pt-8",
    "pb-8",
    "bg-white"
    
];
