import classNames from "classnames";
import { image, wrapper } from "./styles/badges-classes";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Badges: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div className={classNames(className, wrapper.join(" "))}>
      <LazyLoadImage
        className={image.join(" ")}
        srcSet={`
					/assets/images/badges/google-review/small.webp 100w,
					/assets/images/badges/google-review/small.png 100w,
					/assets/images/badges/google-review/medium.webp 190w,
					/assets/images/badges/google-review/medium.png 190w,
					/assets/images/badges/google-review/large.webp 378w,
					/assets/images/badges/google-review/large.png 400w,
				`}
        sizes="(max-width:736px) 100px, (max-width:1024px) 190px, 400px"
        width="190px"
        height="65px"
        src="/assets/images/badges/google-review/small.png"
        alt=""
      />
      <a href={"https://www.a-lign.com/"} target={"_blank"}>
        <LazyLoadImage
          className={image.join(" ")}
          srcSet={`
					/assets/images/badges/soc-2/soc-2-small.webp 100w,
					/assets/images/badges/soc-2/soc-2-small.png 100w,
					/assets/images/badges/soc-2/soc-2-medium.webp 150w,
					/assets/images/badges/soc-2/soc-2-medium.png 150w,
					/assets/images/badges/soc-2/soc-2-large.webp 200w,
					/assets/images/badges/soc-2/soc-2-large.png 200w,
				`}
          sizes="(max-width:736px) 100px, (max-width:1024px) 150px, 200px"
          width="150px"
          height="203px"
          src="/assets/images/badges/soc-2/soc-2-small.png"
          alt="System and Organization Controls attestation Badge"
        />
      </a>
      <LazyLoadImage
        className={image.join(" ")}
        srcSet={`
					/assets/images/badges/hipaa-compliance-verification/small.webp 80w,
					/assets/images/badges/hipaa-compliance-verification/small.png 80w,
					/assets/images/badges/hipaa-compliance-verification/medium.webp 140w,
					/assets/images/badges/hipaa-compliance-verification/medium.png 140w,
					/assets/images/badges/hipaa-compliance-verification/large.webp 300w,
					/assets/images/badges/hipaa-compliance-verification/large.png 300w,
				`}
        sizes="(max-width:736px) 80px, (max-width:1024px) 140px, 300px"
        width="136px"
        height="73px"
        src="/assets/images/badges/hipaa-compliance-verification/small.png"
        alt=""
      />
      <LazyLoadImage
        className={image.join(" ")}
        srcSet={`
					/assets/images/badges/iso-certified/small.webp 80w,
					/assets/images/badges/iso-certified/small.png 80w,
					/assets/images/badges/iso-certified/medium.webp 140w,
					/assets/images/badges/iso-certified/medium.png 140w,
					/assets/images/badges/iso-certified/large.webp 300w,
					/assets/images/badges/iso-certified/large.png 300w,
				`}
        sizes="(max-width:736px) 80px, (max-width:1024px) 140px, 300px"
        width="137px"
        height="63px"
        src="/assets/images/badges/iso-certified/small.png"
        alt=""
      />
      <LazyLoadImage
        className={image.join(" ")}
        srcSet={`
					/assets/images/badges/ce-certified/small.webp 40w,
					/assets/images/badges/ce-certified/small.png 40w,
					/assets/images/badges/ce-certified/medium.webp 80w,
					/assets/images/badges/ce-certified/medium.png 80w,
					/assets/images/badges/ce-certified/large.webp 150w,
					/assets/images/badges/ce-certified/large.png 150w,
				`}
        sizes="(max-width:736px) 40px, (max-width:1024px) 80px, 150px"
        width="72px"
        height="66px"
        src="/assets/images/badges/CE-certified.png"
        alt=""
      />
    </div>
  );
};

export default Badges;
