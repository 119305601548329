import { useTranslation, Trans } from "react-i18next";
import ContentHeading from "@/components/content-heading";
// Tailwind Classes
import {
    wrapper,
    img,
    headingContainer,
    descriptionContainer,
    heading,
    subheading,
    button,
    description,
    horaContainer,
    horaHeadingContainer,
    horaHeading,
    horaSubHeading,
    disclaimerContainer,
    disclaimerText,
    disclaimerButton,
} from "./style/carousel-item-classes";
import { isMobile } from "react-device-detect";
import CustomLink from "@/components/custom-link";
import { LazyLoadImage } from "react-lazy-load-image-component";
// Interfaces
import {IProjectPartnerItem} from "@/components/home/projects-and-partners/interfaces/iProjectPartnersItem";
const ProjectPartnersCarouselItem: React.FC<IProjectPartnerItem> = (props) => {
    const { project, image, url } = props;
    const { t } = useTranslation();

    return (
        <div className={wrapper.join(" ")} title={t(`index.projects-and-partners.projects.${project}.seo.title`)} aria-label={t(`index.projects-and-partners.projects.${project}.seo.aria-label`)}>
            <img
                className={img.join(" ")}
                srcSet={`
					${image.url}/small.webp 150w,
					${image.url}/small.png 150w,
					${image.url}/medium.webp 220w,
					${image.url}/medium.png 220w,
					${image.url}/large.webp 327w,
					${image.url}/large.png 327w
				`}
                sizes={`
					(max-width:736px) 150px,
					(max-width:1280px) 220w,
					327w
				`}
                src={`${image.url}`}
                alt={image.alt}
            />
            {project.toLowerCase() !== "hora" ? (
                <>
                    <div className={headingContainer.join(" ")}>
                        <p className={heading.join(" ")}>
                            <Trans components={{ sup: <sup className='text-[50%] -top-4, mobile:-top-2'/> }}>index.projects-and-partners.projects.{project}.heading</Trans>
                        </p>
                        <p className={subheading.join(" ")}>{t(`index.projects-and-partners.projects.${project}.subheading`)}</p>
                        {!isMobile && (
                            <CustomLink url={url} external={true}>
                                <button className={button.join(" ")} type="button">
                                    {t(`index.projects-and-partners.projects.${project}.button.text`)}
                                </button>
                            </CustomLink>
                        )}
                    </div>
                    <div className={descriptionContainer.join(" ")}>
                        <p className={description.join(" ")}>
                            <Trans components={{ sup: <sup /> }}>index.projects-and-partners.projects.{project}.description</Trans>
                        </p>
                        <ContentHeading
                            h="p"
                            fontWeight="font-bold"
                            fontSize="mobile:text-1xl tablet:text-3xl"
                            color="text-white"
                            background="bg-primary bg-opacity-40"
                            backdropPosition="left-2"
                        >
                            {t(`index.projects-and-partners.projects.${project}.subtext`)}
                        </ContentHeading>
                    </div>
                    {isMobile && (
                        <CustomLink url={url} external={true}>
                            <button className={button.join(" ")} type="button">
                                {t(`index.projects-and-partners.projects.${project}.button.text`)}
                            </button>
                        </CustomLink>
                    )}
                </>
            ) : (
                <>
                    <div className={horaContainer.join(" ")}>
                        <div className={horaHeadingContainer.join(" ")}>
                            <p className={horaHeading.join(" ")}>
                                <Trans components={{ sup: <sup /> }}>index.projects-and-partners.projects.{project}.heading</Trans>
                            </p>
                            <p className={horaSubHeading.join(" ")}>{t(`index.projects-and-partners.projects.${project}.subheading`)}</p>
                        </div>
                        <p className={description.join(" ")}>
                            <Trans components={{ sup: <sup /> }}>index.projects-and-partners.projects.{project}.description</Trans>
                        </p>
                        <div className={disclaimerContainer.join(" ")}>
                            <CustomLink url={url} external={true}>
                                <button className={disclaimerButton.join(" ")} type="button">
                                    {t(`index.projects-and-partners.projects.${project}.disclaimer.button.text`)}
                                </button>
                            </CustomLink>
                            <p className={disclaimerText.join(" ")}>
                                <Trans components={{ sup: <sup /> }}>index.projects-and-partners.projects.{project}.disclaimer.text</Trans>
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProjectPartnersCarouselItem;

// data.json info for hearWho when it's required again:
// {
//     "project": "hearWHO",
//     "image": {
//     "url": "/assets/images/projects-and-partners/hearWHO",
//         "alt": "hearWHO"
// },
//     "url": "https://www.who.int/health-topics/hearing-loss/hearwho"
// },
