export const wrapper = [
    // Mobile
    "mobile:pt-5",
    "mobile:pb-5",
    // Tablet
    "tablet:pt-10",
    "tablet:pb-10",
    // All
    "max-w-full",
    "overflow-hidden",
    "w-full",
    "bg-white"
];
